export const translations = {
    en: {
        loading: 'Loading',
        downloadApp: 'Download our app!',
        downloadAppToOwn: 'Accept the compliment!',
        downloadAppModalText: 'Download our app to accept given compliments, create your own compliments and many more.',
    },
    ru: {
        loading: 'Загрузка',
        downloadApp: 'Скачайте приложение!',
        downloadAppToOwn: 'Принять комплимент',
        downloadAppModalText: 'Установите наше приложение, чтобы принять подаренный комплимент, создавать свои комплименты, и не только.',
    }
}