import React, { useState } from 'react';
import './delete-account.css'; // Import the CSS file

const DeleteAccount = () => {
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsButtonActive(emailRegex.test(newEmail) && isChecked);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsButtonActive(emailRegex.test(email) && !isChecked);
  };

  const handleRequestDeletion = () => {
    fetch('https://server.wowdevelop.com/ccloud-delete-account?email=' + email);
    setEmail('');
    setIsChecked(false)
    setIsButtonActive(false)
    alert('Your request submited. You will get an email with further instructions. See you.' );
  };

  return (
    <div className="delete-account-container">
      <h2>Delete Compliment Cloud Account</h2>
      <p className="warning-text">
        Warning: Deleting your account will permanently remove all your data, and this action cannot be undone. All data will be lost even purchased products. No refund available. No way to restore the account.
      </p>
      <label>
        Email:&nbsp;
        <input type="email" value={email} onChange={handleEmailChange} />
      </label>
      <br />
      <label className="checkbox-label">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        Yes, I read the warning and I agree
      </label>
      <br />
      <button onClick={handleRequestDeletion} disabled={!isButtonActive}>
        Request Deletion
      </button>
    </div>
  );
};

export default DeleteAccount;
