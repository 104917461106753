import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const CheckMailPage = () => {

    const { code } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [result, setResult] = useState(false);
    const [lang, setLang] = useState('en')

    const processCode = async () => {
        const data = await fetch(`https://server.wowdevelop.com/api/member/verify-email-check/${code}`);
        setResult(data.status === 200)
        setLoaded(true)
    }

    useEffect(() => {
        const userLang = navigator.language || navigator.userLanguage; 
        if (userLang === 'ru' || userLang === 'ru-RU' ) {
            setLang('ru')
        }

        processCode().catch(console.error);
        // eslint-disable-next-line
    }, [code])

    if (!loaded) {
        return (
          <div>{ lang === 'ru' ? 'Загрузка' : 'Loading'}</div>
        )
    }

    if (result) { // email verified
        return (
            <div>
                { lang === 'ru' ? 'Ваша почта успешно подтверждена. Эту страницу можно закрыть.' : 'Your email was successfully confirmed. You can safely close this page.' }
            </div>
        )
    } else { // something went wrong
        return (
            <div>
                { lang === 'ru' 
                    ? 'Произошла ошибка подтверждения почты. Возможно ссылка устарела. Пожалуйста попробуйте ещё раз.' 
                    : 'An error has occured while confirming your email. Perhaps the link is expired. Please start the verification process from scratch.' }
            </div>
        )
    }
}

export default CheckMailPage;