import { Route, Routes } from 'react-router-dom';
import './App.css';
import ComplimentComponent from './screens/compliment/compliment';
import Home from './screens/home/home';
import DeleteAccount from './screens/delete-account/delete-account';
import CheckMailPage from './screens/checkmail/checkmail';

function App() {
  return (
    <Routes>
        <Route index element={<Home />} />
        <Route path="/compliment/:id" element={<ComplimentComponent />} />
        <Route path="/compliment/:id/:ownkey/*" element={<ComplimentComponent />}  />
        <Route path="/checkmail/:code" element={<CheckMailPage />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;