import React, { useEffect, useState } from 'react';
import {isMobile} from 'react-device-detect';
import './compliment.css';
import { useParams } from 'react-router-dom';
import { translations } from '../../translations';

const ComplimentComponent = () => {
  
  const [comp, setComp] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [showModal, setShowModal] = useState(isMobile);
  const [notf, setNotf] = useState(false);
  const [lang, setLang] = useState('en');
  let { id, ownkey } = useParams();

  const loadCompliment = async () => {
    const data = await fetch(`https://server.wowdevelop.com/api/compliment/${id}`);
    if (data.status === 200) {
      const json = await data.json();
      setComp({
        text: json.text,
        postScriptum: json.signature, 
        isPremium: json.rateType > 0, 
        senderName: json.senderName, 
        senderAvatar: processImage(json.senderAvatarUrl), 
        addresseeName: json.receiverName, 
        addresseeAvatar: processImage(json.receiverAvatarUrl), 
      })

      setLoaded(true)
    } else {
      setNotf(true);
    }
  }

  const processImage = (imageUrl) => {
    if (imageUrl == null) return '/assets/img/avatar-empty.png';
    else if (imageUrl.startsWith('images')) {
      return '/' + imageUrl;
    }

    return imageUrl;
  } 

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang === 'ru' || userLang === 'ru-RU' ) {
      setLang('ru');
    }

    loadCompliment().catch(console.error);
  }, [id])

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const goToStore = (type) => {
    if (type === 'ios') {
      window.location = 'https://apps.apple.com/us/app/compliment-cloud/id6470207411'
    } else {
      window.location = 'https://play.google.com/store/apps/details?id=com.wowdevelop.ccloud&pli=1'
    }
    setShowModal(false);
  };

  if (notf) {
    return (
      <h1>404</h1>
    )
  }

  if (!loaded) {
    return (
      <h1>{ translations[lang].loading }</h1>
    )
  }

  const { text, postScriptum, isPremium, senderName, senderAvatar, addresseeName, addresseeAvatar } = comp;

  return (
    <div className={`compliment ${isPremium ? 'premium' : 'simple'}`}>
      
      <div className="compliment-text">{text}</div>
      {postScriptum ? (
        <div className="signature">{postScriptum}</div>
      ) : (
        <div className="compliment-info">
          <div className="sender-info">
            <img className="sender-avatar" src={senderAvatar} alt={`${senderName}'s Avatar`} />
            <span className="sender-name">{senderName}</span>
          </div>
          <span className="arrow">→</span>
          <div className="addressee-info">
            <img className="addressee-avatar" src={addresseeAvatar} alt={`${addresseeName}'s Avatar`} />
            <span className="addressee-name">{addresseeName}</span>
          </div>
        </div>
      )}


      <div className="floating-badge" onClick={openModal}>
        <div>
          <lottie-player src="https://lottie.host/ee4a0e21-ad32-4faa-ad1d-d4664893d0ef/XTjKHwDuWa.json" background="transparent" speed="1" style={{width: '50px', height: '50px'}} loop autoplay direction="1" mode="normal"></lottie-player>
        </div>
        <div>{ ownkey !== undefined && ownkey !== null ? translations[lang].downloadAppToOwn : translations[lang].downloadApp } </div>
        
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close" onClick={closeModal}>
             &times;
            </button>
            <p>
              { translations[lang].downloadAppModalText }
            </p>
            <div className="app-buttons">
              <button className="app-store-button" onClick={() => goToStore('ios')}>App Store</button>
              <button className="google-play-button" onClick={() => goToStore('android')}>Google Play</button>
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

export default ComplimentComponent;
