import React, { useEffect } from "react";

export default function Home() {
    
    useEffect(() => {
        const rnd = Math.floor(Math.random() * 100000);
        const userLang = navigator.language || navigator.userLanguage; 
        if (userLang === 'ru' || userLang === 'ru-RU' ) {
            window.location = '/welcome-ru.html?p=' + rnd;
            return;
        }
        window.location = '/welcome.html?p=' + rnd; 
    }, [])

    return (
        <div></div>
    )
}